/* Styles go here */

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  background-color: #effbff;
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #ffffff;
}

button:focus {
  outline: none;
}
